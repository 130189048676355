@if(isDotLive){
<ng-container>
  <div
    *ngIf="isPwa"
    role="alert"
    class="alert alert-warning m-0 fade show text-center"
  >
    <div>
      Please sign in at
      <a
        class="terms-link pointer"
        href="https://app.guardtracker.com.au/"
        target="_blank"
        rel="noopener noreferrer"
        >https://app.guardtracker.com.au/</a
      >
    </div>
    <div>
      This version of the app has been discontinued, please uninstall and/or
      remove this app from your device
    </div>
  </div>
  <div
    *ngIf="!isPwa"
    role="alert"
    class="alert alert-warning m-0 fade show text-center"
  >
    Please switch to
    <a
      class="terms-link pointer"
      href="https://app.guardtracker.com.au/"
      target="_blank"
      >https://app.guardtracker.com.au/</a
    >
    as we will be retiring app.guardtracker.live soon.
  </div>
</ng-container>
} @else{
<ng-container>
  <div *ngIf="successMessage || errorMessage" [ngStyle]="messageStyle">
    <!-- Success Message -->
    <div
      (click)="clearAlert()"
      *ngIf="successMessage"
      role="alert"
      class="alert alert-info m-0 fade show"
    >
      {{ successMessage }}
    </div>
    <!-- Error Message -->
    <div
      (click)="clearAlert()"
      *ngIf="errorMessage"
      role="alert"
      class="alert alert-warning m-0 fade show"
    >
      {{ errorMessage }}
    </div>
  </div>

  <router-outlet></router-outlet>
  <div
    *ngIf="showSpinner"
    class="d-flex justify-content-center gt-loader-container"
  >
    <div class="spinner-border text-white gt-loader" role="status"></div>
    <span class="text-light custom-loading-text text-center mx-5">{{
      spinnerMessage
    }}</span>
  </div>
</ng-container>
}
