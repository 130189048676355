import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { from, lastValueFrom, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { isLargeScreen } from '../global.variable';
import { AppService } from './app.service';
import { DataCheckService } from './shared/services/data-check.service';
import { DeviceInfoService } from './shared/services/device-info.service';
@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  isSuperUser: boolean = false;
  isAppWorkingFullyBool: boolean = true;
  isLargeScreen = isLargeScreen;
  constructor(
    private appService: AppService,
    private router: Router,
    private dataCheckService: DataCheckService,
    private deviceInfoService: DeviceInfoService
  ) {
    this.isSuperUser = this.dataCheckService.isSuperUser();
    this.deviceInfoService.deviceInfoSubject.subscribe((value: any) => {
      if ('appStatus' in value) {
        this.isAppWorkingFullyBool = value?.appStatus;
      }
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // convert promise to observable using 'from' operator
    return from(this.handle(req, next)).pipe(
      tap((event: any) => {
        if (
          // case wehere the server was returning > 500 errors or event oflfine mode and then suddenly returns < 500 implying server communication
          event instanceof HttpResponse &&
          event.status >= 200 &&
          event?.status < 500 &&
          !this.isAppWorkingFullyBool &&
          navigator.onLine &&
          !event?.body?.serviceWorkerResponse
        ) {
          this.deviceInfoService.deviceInfoSubject.next({ appStatus: true });
        }
      }),
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            // the token is invalid; clear the localstorage so that 'LoadGuard' wont redirect back into the app.
            const user = this.appService.getUserData();
            const gtAppTheme: any = this.appService.getGTAppTheme();
            localStorage.clear();
            if (user?.token_expiry) {
              localStorage.setItem('email', user?.profile?.email);
            }
            this.appService.setGTAppTheme(gtAppTheme);
            this.router.navigate(['/login']);
          }
          if (error.status === 404) {
            this.router.navigate(['/404']);
          }
          if (error.status >= 500) {
            if (this.appService.getToken()) {
              if (this.isLargeScreen) {
                this.router.navigate(['/503']);
              } else {
                this.deviceInfoService.deviceInfoSubject.next({
                  appStatus: false,
                });
              }
            } else {
              this.router.navigate(['/503']);
            }
          }
          if (error.status === 403) {
            if (this.isSuperUser === true) {
              this.router.navigate(['gtadmin/dashboard']);
            } else {
              this.router.navigate(['/']);
            }
          }
          // Payment Failed Calls
          if (error.status === 402) {
            if (this.isSuperUser) {
              this.router.navigate(['/gtadmin/dashboard']);
            } else if (this.dataCheckService?.isSubscriberAdmin()) {
              this.router.navigate([
                '/subscription',
                {
                  state: error.error.message,
                },
              ]);
            } else {
              this.router.navigate(['/']).then(() => {
                window.location.reload();
              });
            }
          }
        }
        return throwError(() => error);
      })
    );
  }
  async handle(req: HttpRequest<any>, next: HttpHandler) {
    const userAccessToken: any = this.appService.getToken();
    if (userAccessToken)
      req = await this.addToken(req, this.appService.getToken());
    return await lastValueFrom(next.handle(req));
  }

  private async addToken(
    request: HttpRequest<any>,
    token: string
  ): Promise<HttpRequest<any>> {
    const apiResponse: any =
      await this.deviceInfoService.getApiHeadersAndExtras(token);
    const headerDict = apiResponse?.headerDict;
    const reqBody = apiResponse?.reqBody;

    return request.clone({
      setHeaders: headerDict,
      body: {
        ...request.body,
        ...(reqBody ? reqBody : {}),
      },
    });
  }
}
